// global
export const g = {
	EVENT: 'EVENT',
	INCENTIVE: 'INCENTIVE',
	PARTS: 'PARTS',
	SERVICE: 'SERVICE',
	VEHICLE: 'VEHICLE'
};

// actions
export const a = {
	SET_ACCOUNT: 'SET_ACCOUNT',
	SET_PERSONALIZED: 'SET_PERSONALIZED',
	SET_TEST_PARAMS: 'SET_TEST_PARAMS',
	SET_HAS_DATA: 'SET_HAS_DATA',
	SET_POSITION_DATA: 'SET_POSITION_DATA',
	CLOSE_MODAL: 'CLOSE_MODAL',
	OPEN_MODAL: 'OPEN_MODAL',
	SET_LISTINGS_CAROUSEL_SLIDE_CONDITIONS:
		'SET_LISTINGS_CAROUSEL_SLIDE_CONDITIONS',
	SET_VEHICLE_ID: 'SET_VEHICLE_ID',
	SET_ACCOUNT_ID: 'SET_ACCOUNT_ID'
};

// actions
export const aR = {
	DESKTOP_10X1: 'Desktop10x1',
	DESKTOP_4X1: 'Desktop4x1',
	DESKTOP_4X3: 'Desktop4x3',
	MOBILE_4X3: 'Mobile4x3',
	MOBILE_4X3_SLIDESHOW: 'Mobile4x3_slideshow',
	MOBILE_4X3_COUPON: 'Mobile4x3_coupon',
	MOBILE_6X1: 'Mobile6x1'
};

export const programsApectRatios = {
	bmw: {
		desktop: {
			width: 475,
			height: 194
		},
		mobile: {
			width: 400,
			height: 163
		}
	},
	mini: {
		desktop: {
			width: 18,
			height: 7
		},
		mobile: {
			width: 3,
			height: 2
		}
	},
	mazda: {
		desktop: {
			width: 32,
			height: 9
		},
		mobile: {
			width: 192,
			height: 179
		}
	},
	stellantis: {
		desktop: {
			width: 16,
			height: 5
		},
		mobile: {
			width: 4,
			height: 3
		}
	}
};

export const groupsWithSpecialAspectRatio = [
	{ name: 'bmw', members: ['bmwgroup'] },
	{ name: 'mini', members: ['minigroup'] },
	{ name: 'mazda', members: ['mnao'] },
	{
		name: 'stellantis',
		members: ['cllc', 'cllcpr', 'fca', 'fiat', 'alfaromeo']
	}
];

export const aspectRatios = new Map([
	['Desktop10x1', 'banner'],
	['Desktop4x1', 'slideshow'],
	['Desktop4x3', 'listing'],
	['Mobile4x3_slideshow', 'slideshow'],
	['Mobile4x3_coupon', 'listing'],
	['Mobile6x1', 'banner']
]);

export const aspectRatiosProportions = new Map([
	['Desktop10x1', [10, 1]],
	['Desktop4x1', [4, 1]],
	['Desktop4x3', [4, 3]],
	['Mobile4x3_slideshow', [4, 3]],
	['Mobile4x3_coupon', [4, 3]],
	['Mobile6x1', [6, 1]]
]);

export const perpetualOptions = {
	NO_SHOW: 'NO_SHOW',
	NO_SHOW_ON_SPECIAL: 'NO_SHOW_ON_SPECIAL'
};

export const BLANK_GIF = 'https://static.dealer.com/images/blank.gif';
export const LEAD_FORM_URL = '/promotions/lead-form.htm?promotionId=';

export const DOWNSIZE_VALUES = {
	banner: {
		MOBILE: 400,
		DESKTOP: 400
	},
	slideshow: {
		MOBILE: 400,
		DESKTOP: 2000
	},
	listing: {
		MOBILE: 400,
		DESKTOP: 500
	}
};

// Contact params to select Dealer Name, Address, Phone numbers from wsm-contact-transformer
export const CONTACT_OPTIONS = {
	phoneType: 'Sales', // Display Sales phone as default
	deptPhoneType: 'Sales, Service, Parts', // Display department phones if it has
	displayPhone: true,
	displaySiteUrl: false
};

export const PARENT_ACCOUNTS = [
	'mnao',
	'cllc',
	'cllcpr',
	'fca',
	'fiat',
	'alfaromeo',
	'bmwgroup',
	'minigroup'
];
